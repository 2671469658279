<template>
    <div>
        <div>
            <b-card no-body>
                <b-tabs card v-model="activeTab">
                    <div class="col-md-10 w-100 mx-auto">
                        <b-tab title="FranceTV">
                            <b-card-text> </b-card-text>

                            <b-form @submit.stop.prevent>
                                <b-row>
                                    <b-col cols="6">
                                        <b-form-group label="Brand" label-for="input-brand">
                                            <b-form-select v-model="selectedBrand" :options="brands"
                                                required></b-form-select>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-btn variant="success" v-on:click="downloadCsv" class="d-flex mt-2"">
                                                                          DownloadCsv
                                                                        </b-btn>
                                                                    </b-col>
                                                                </b-row>
                                                            </b-form>
                                                            <div>

                                                            </div>
                                                        </b-tab>

                                                    </div>
                                                </b-tabs>
                                            </b-card>
                                        </div>
                                    </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import VueJsonViewer from 'vue-json-viewer';
import 'vue-json-viewer/style.css';

var FormData = require('form-data');
var fs = require('fs');

export default {
    data() {
        return {
            loading: false,
            selectedBrand: '',
            brands: [],
            point_codes: '',
            activeTab: 0
        };
    },
    components: {
        VueJsonViewer,
    },

    watch: {
        selectedBrand(brand) {
            if (brand) {
                this.getPosOfInterest(brand);
            }
        },
    },

    created() {
        this.getBrands();
    },

    computed: {},
    methods: {
        getBrands() {
            let tempArray = [];
            this.$store.dispatch('app/getBrands').then((res) => {
                res.data.map((item) => {
                    tempArray.push(item.name);
                });
                this.brands = tempArray;
            });

        },

        async getPosOfInterest(brand) {
            let point_codes = []
            const pois = this.$store.dispatch('app/getPosOfInterest', brand).then((res) => {
                res.data.map((item) => point_codes.push(item.point_code))
                this.point_codes = point_codes.join(",")
            })

        },

        async downloadCsv() {
            try {
                this.$root.toggleGlobalLoading(true);
                const response = await this.$store.dispatch('app/downloadFtvPrices', { brand: this.selectedBrand, point_code: this.point_codes });
                const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${this.selectedBrand}` + '_prices.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
                this.$root.toggleGlobalLoading(false);
            } catch (error) {
                console.log(error);
                this.$root.toggleGlobalLoading(false);
            }
        }

    },
};
</script>

<style></style>
